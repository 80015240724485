import "./index.scss";
import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div className="col-12 mad-about-page">
        <div className="row mad-about-details mad-dark d-none d-sm-flex">
          <div className="col-12">
            <h2>About</h2>
          </div>
        </div>

        <div className="row mad-about-landing">
          <div className="col-12 col-lg-6 mad-image-container">
            <img src="/img/about.jpg" alt="Woman with goldendoodles" />
          </div>
          <div className="col-12 col-lg-6 mad-about-bio">
            <h4>Ruth Enas - Owner</h4>
            <p>
              After losing my husband in 2015 to Glioblastoma I didn’t know how
              I was going to support myself financially. After talking to a dear
              friend of mine, she asked if I would be interested in breeding
              mini goldendoodles. The thought was very exciting as I love dogs,
              yet also a very daunting task. My dear friend, Linda, told me that
              she would give me one of her sweet puppies and I could try it out
              and see if I liked it or not, no strings attached. <br />
              <br />
              In 2016 I got my sweet Mauzy (left) and in 2018 she had her first
              litter of puppies. There were some wonderful days and then there
              were some very stressful and hard days, but I survived the process
              and the joy that I get from seeing the smiles of the new families
              who get their puppy is a gift.
            </p>
          </div>
        </div>

        <div className="row mad-about-details mad-uiblue20">
          <div className="col-12">
            <h4>Meet The Parents</h4>
            <p style={{ padding: "0 20px" }}>
              We have only one breeding dog at a time, which allows us to put
              all our energy into raising the puppies. They are raised in our
              home with a lot of love, attention and individual one-on-one time
              rather than outside in a kennel with lack of human contact. We
              start
              <a
                href="https://breedingbetterdogs.com/article/early-neurological-stimulation"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                ENS&nbsp;
              </a>
              on day three.
            </p>

            <div className="row mad-about-profiles">
              <div className="col-md-6 profile">
                <div className="wrapper">
                  <img src="/img/oak.jpeg" alt="Oakley" />
                  <div className="text-wrapper">
                    <h4 className="mad-pup-title">Oakley</h4>
                    <p>
                      Oakley is a 16 lbs multi-generational goldendoodle. She
                      has a super sweet personality and loves to snuggle.
                    </p>
                    <a
                      href="http://embk.me/pinkpoppy?utm_campaign=cns_ref_dog_pub_profile&utm_medium=other&utm_source=embark"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Embark DNA Testing
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 profile">
                <div className="wrapper">
                  <img src="/img/tiggie.jpeg" alt="Tiggie" />
                  <div className="text-wrapper">
                    <h4 className="mad-pup-title">Tiggie</h4>
                    <p>
                      For more information on Tiggie visit{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://mustanghills.weebly.com/"
                      >
                        Mustanghills doodles & poodles
                      </a>
                    </p>
                    <a
                      href="http://embk.me/mustangtiggie?utm_campaign=cns_ref_dog_pub_profile&utm_medium=other&utm_source=embark"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Embark DNA Testing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
