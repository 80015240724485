import "./index.scss";
import React from "react";

const Home = () => {
  return (
    <div className="col-12 mad-home-page">
      <div className="row mad-landing text-center">
        <div className="col-12 mad-landing-text-container flex-column">
          <div className="mad-landing-text">
            <h2>
              Your New
              <br />
              Best Friend
            </h2>
            {/* <p>
              Our <b>amazing doodles</b> can't wait to bless{" "}
              <br className="d-none d-sm-inline" /> their new{" "}
              <b>fur-ever homes</b>.
            </p> */}

            <div className="mad-landing-cta-wrapper justify-content-center">
              <a
                href="https://forms.gle/3wwy7hS28kD3UYdb7"
                target="_blank"
                rel="noopener noreferrer"
                className="mad-landing-cta"
              >
                Apply For a Puppy
              </a>
            </div>
          </div>
        </div>

        <div className="mad-learn-more">
          <p>
            <b>LEARN MORE</b>
          </p>
          <p>
            <i className="fa fa-caret-down fa-3x" />
          </p>
        </div>
      </div>

      <div className="row mad-about mad-theme">
        <div className="col-12 mad-about-upper">
          <div className="row">
            <div className="col-12 col-md-6 mad-about-image flex-column order-1 order-lg-1">
              <img src="/img/pups.jpg" alt="puppy running on grass" />
              {/* <div className="mad-accent-left" /> */}
            </div>

            <div className="col-12 col-md-6 mad-about-text flex-column order-2 order-lg-2">
              <h4>Why Goldendoodles?</h4>
              <p>
                Goldendoodles are the perfect balance of the Golden Retriever
                and the Poodle. Goldendoodles are known for being cheerful,
                loyal, easy to please, intelligent, social, non-aggressive,
                excellent with children and they don’t shed. They love attention
                and thrive in an active home, but they can also adapt to a
                quieter lifestyle. They have a high desire to please their
                humans which makes them easy to train. Early training is
                important in establishing a good relationship with your
                goldendoodle.
                <br />
                <br />
                Doodles comes in four different sizes:
                <br />
                <b>Petite:</b> Height below 14”, typically 25 lbs or less
                <br />
                <b>Miniature:</b> Height over 14” but under 17”, typically 26-35
                lbs
                <br />
                <b>Medium:</b> Height over 17” but under 21”, typically 36-50
                lbs
                <br />
                <b>Standard:</b> Over 21”, typically 51 lbs or more
                <br />
                <br />
                Some of the different generations (there are many more
                generations then just these two):
                <br />
                F1: Golden Retriever X Poodle (could have low to moderate
                shedding)
                <br />
                F1b: Goldendoodle X Poodle (generally good for those looking for
                non-shedding)
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mad-available-container">
        <div className="col-12 col-md-6 mad-available-images flex-column order-2 order-md-1"></div>

        <div className="col-12 col-md-6 mad-available-info flex-column order-1 order-md-2 mad-dark">
          <h4>Reserving A Puppy</h4>
          <p>
            If you are interested in a puppy, you will need to fill out an{" "}
            <a
              href="https://forms.gle/3wwy7hS28kD3UYdb7"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              application form
            </a>
            . Within 24 hours we will let you know if we think you would be a
            great match for one of our puppies. We will send you our contract
            once puppies become available. Once the contract has been signed and
            approved you will need to send a non-refundable deposit.
          </p>
        </div>
      </div>

      {/* <div className="row mad-available-container lower">
        <div className="col-12 col-md-6 mad-available-image-right flex-column order-1 order-md-2"></div>

        <div className="col-12 col-md-6 mad-available-info flex-column order-2 order-md-1">
          <h4>Current Litter</h4>
          <p>
            We are currently accepting applications for our upcoming litters. If
            you are interested in purchasing a puppy in an upcoming litter, you
            may{" "}
            <a
              href="https://forms.gle/3wwy7hS28kD3UYdb7"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              apply online
            </a>{" "}
            and reserve a spot on our waitlist.
          </p>

          <Link to="/reviews" className="btn btn-primary">
            View Past Litters
          </Link>
        </div>
      </div> */}
    </div>
  );
};
export default Home;
